import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Section, Title, Text, Box, ButtonIcon } from '../../components/Core'
import PageWrapper from '../../components/PageWrapper'
import Screenshot from '../../components/Screenshot'
import NextLink from '../../components/NextLink'
import WebsiteLink from '../../components/WebsiteLink'
import Slides from '../../components/Slides'
import Lighthouse from '../../components/Lighthouse'
import Contact from '../../sections/common/Contact'
import Hero from '../../sections/common/Hero'
import { Keys, Key } from '../../sections/keys/Keys'

import favicon from '../../assets/images/resume/favicon.png'
import perform from '../../assets/images/resume/lighthouse-performance.jpg'
import app from '../../assets/images/ba/appointment-app.png'

import resume from '../../assets/images/portfolio/resume.jpg'
import timeline from '../../assets/images/resume/timeline.jpg'
import skills from '../../assets/images/resume/skills.jpg'
import references from '../../assets/images/resume/references.jpg'
import lighthouse from '../../assets/images/resume/lighthouse-100percent.jpg'

const WorkSingle = () => {
    return (
        <>
            <PageWrapper>
                <Hero className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="7">
                                <Text variant="tag">Personal</Text>
                                <Title mb="0.5em">Professional Resume</Title>
                                <Text variant="p" mb="1.6em">
                                    On this micro pwa, I challenged myself to
                                    build an online resume in 1 day that could
                                    score high on lighthouse.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    Doesn't sound very challenging except when
                                    you consider I wrote it using as few
                                    dependencies as possible.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    No material-ui, no styled components, just
                                    straight up sass and create-react-apps
                                    scripts.
                                </Text>
                            </Col>
                            <Col lg="5">
                                <Lighthouse />
                            </Col>
                        </Row>
                    </Container>
                </Hero>

                <div className="mt-lg-3">
                    <Container>
                        <Row>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Client</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Personal
                                </Title>
                            </Col>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Time</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Summer 2022
                                </Title>
                            </Col>
                            <Col lg="4">
                                <Text variant="tag">Website</Text>

                                <Title variant="cardBig" className="mt-3">
                                    <WebsiteLink
                                        src={favicon}
                                        href="https://resume.caseycv.com/"
                                    >
                                        Resume Website
                                    </WebsiteLink>
                                </Title>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Section className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="5" className="mb-5 pr-lg-5">
                                <img
                                    src={perform}
                                    alt="Lighthouse Performance"
                                    className="img-fluid w-100"
                                />
                            </Col>
                            <Col lg="7" className="mb-5 pl-lg-5">
                                <Title variant="cardBig" className="mb-4">
                                    Measuring Performance
                                </Title>
                                <Text variant="p" mb="1.6em">
                                    The idea for lighthouse goes back to the
                                    original mission statement from google. Get
                                    search results in under 1 second.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    For me, building high speed websites are
                                    necessary when providing a majority of
                                    advertising to mobile devices.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    Now a days, performance is not as widely
                                    saught after because of the high speed
                                    broadband internet. I still focus on
                                    performance first because of the mobile
                                    speed scores your landing pages are measured
                                    on for organic and paid search results.
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Keys mt="-5%">
                    <Key name="react" title="01. React" left>
                        React is still my first choice for a front end language.
                        Create React App is a super simple bootstrap that
                        provides a quick launchpad for a modern day PWA.
                    </Key>

                    <Key name="mobile" title="02. Mobile Performance">
                        The key to ranking well in organics and getting the best
                        click through rate for your google adwords landing pages
                        still includes the performance on mobile devices.
                    </Key>

                    <Key name="googlecloud" title="03. Google Cloud" left>
                        There may be easier solutions out there, but I still
                        haven't found a quicker and more performant way to
                        deploy micro-pwa's than Googles App Engine.
                    </Key>

                    <Key name="sass" title="04. Sass">
                        There are a lot of ways to style your React apps these
                        days, but I still find sass to be amoung my favorite
                        ways. The ease of using media queries and full range of
                        modern css makes it one of the highest performing
                        methods.
                    </Key>
                </Keys>

                <Section mt="22%" bg="dark">
                    <Container>
                        <Row
                            css={`
                                margin-top: -35%;
                            `}
                        >
                            <Col xs="12" className="mb-5">
                                <Slides>
                                    <Screenshot
                                        src={skills}
                                        alt="Development Skills"
                                    />
                                    <Screenshot
                                        src={timeline}
                                        alt="Projects Timeline"
                                    />
                                    <Screenshot
                                        src={references}
                                        alt="Professional References"
                                    />
                                    <Screenshot
                                        src={lighthouse}
                                        alt="Lighthouse Perfect Score"
                                    />
                                </Slides>
                            </Col>
                        </Row>

                        <div className="text-center mt-lg-5">
                            <Text
                                variant="tag"
                                className="mb-1"
                                color="lightShade"
                            >
                                Next Project
                            </Text>
                            <NextLink to="/works/book-appointment/">
                                Book Appointment
                            </NextLink>
                        </div>
                    </Container>
                </Section>

                <Box py={4}>
                    <Contact />
                </Box>
            </PageWrapper>
        </>
    )
}
export default WorkSingle
