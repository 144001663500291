import React from 'react'
import { Box, Text, Title } from '../Core'

const LighthouseScore = ({
    label,
    bg = '#e6faf0',
    color = '#00cc66',
    ...rest
}) => {
    return (
        <Box
            css={`
                display: flex;
                flex-direction: column;
                align-items: center;
            `}
        >
            <Box
                bg={bg}
                color={color}
                px="1rem"
                py="1rem"
                borderRadius="50rem"
                border={`5px solid ${color}`}
                width="66px"
                height="66px"
                css={`
                    text-align: center;
                    font-weight: bold;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                `}
                {...rest}
            >
                100
            </Box>
            <Text variant="small">{label}</Text>
        </Box>
    )
}

const Lighthouse = ({}) => (
    <Box
        css={`
            height: 300px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
        `}
    >
        <Title variant="cardBig" mb="1em">
            Lighthouse: Perfect Score
        </Title>
        <Box
            css={`
                text-align: center;
                display: flex;
                justify-content: space-around;
            `}
        >
            <LighthouseScore label="Performance" />
            <LighthouseScore label="Accessiblility" />
            <LighthouseScore label="Best Practices" />
            <LighthouseScore label="SEO" />
        </Box>
    </Box>
)

export default Lighthouse
